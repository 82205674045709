import { lsGet, lsSet } from './utilities'

const BODY = 'body'
const TOGGLE_BUTTON = 'button[data-drawer-toggle]'
const CLOSE_BUTTON = 'button[data-drawer-close]'
const TOOLBAR = 'Toolbar'

const open = (options) => {
  (document.querySelector(BODY)).classList.add('ovh')
  options.drawerElement.setAttribute('open', '')
  options.drawerPanelElement.classList.add('Drawer__panel--open')
  
  if (options.origin === 'toolbar') {
    options.toolbarElement.classList.add('Toolbar--open')
  }
  closeOthers(options)
}

const close = (options) => {
  const drawerList = lsGet('cdv-drawers')

  options.drawerElement.removeAttribute('open')
  options.drawerPanelElement.classList.remove('Drawer__panel--open')
  options.drawerPanelElement.classList.add('Drawer__panel--closing')

  if (drawerList.length < 1) {
    (document.querySelector(BODY)).classList.remove('ovh')
  }
  setTimeout(() => {
    options.drawerPanelElement.classList.remove('Drawer__panel--closing')

    // Prenvent Drawer to pass under Navbar if playing too quickly with Toolbar
    if (drawerList.length < 1) {
      options.toolbarElement.classList.remove('Toolbar--open')
    }
  }, 1000)
}

// Allow to group details together to keep only one active at the same time
const closeOthers = (options) => {
  let drawerList = lsGet('cdv-drawers')
  const group = options.drawerElement.getAttribute('data-drawers-group')
  const otherDrawers = document.querySelectorAll(`[data-drawers-group="${group}"]`)

  if (drawerList.length > 1) {
    otherDrawers.forEach(d => {
      if (d !== options.drawerElement) {
        const drawerId = d.getAttribute('id')
        drawerList = lsGet('cdv-drawers')
        const targetIndex = drawerList.indexOf(drawerId)

        if (targetIndex > -1) {
          const dPanel = document.getElementById(`${d.getAttribute('id')}-panel`)
          const otherOptions = {
            drawerElement: d,
            drawerPanelElement: dPanel,
            toolbarElement: options.toolbarElement
          }

          drawerList.splice(targetIndex, 1)
          lsSet('cdv-drawers', drawerList)
          close(otherOptions)
        }
      }
    })
  }
  
}

const listenClickToggle = (button, options) => button.addEventListener('click', () => {
  const drawerList = lsGet('cdv-drawers')
  const targetIndex = drawerList.indexOf(options.controlTarget)

  if (targetIndex > -1) {
    drawerList.splice(targetIndex, 1)
    lsSet('cdv-drawers', drawerList)
    close(options)
  } else {
    drawerList.push(options.controlTarget)
    lsSet('cdv-drawers', drawerList)
    open(options)
  }
})

const listenClickClose = (button, options) => button.addEventListener('click', () => {
  const drawerList = lsGet('cdv-drawers')
  const targetIndex = drawerList.indexOf(options.controlTarget)

  if (targetIndex > -1) {
    drawerList.splice(targetIndex, 1)
    lsSet('cdv-drawers', drawerList)
    close(options)
  }
})

const init = (button, type) => {
  const controlTarget = button.getAttribute('aria-controls')
  const drawerElement = document.getElementById(controlTarget)
  const drawerPanelElement = document.getElementById(`${controlTarget}-panel`)
  const origin = (controlTarget.split('__')).includes('Toolbar') ? 'toolbar' : 'navbar'
  const toolbarElement = (document.getElementsByClassName(TOOLBAR))[0]

  const options = {
    controlTarget,
    drawerElement,
    drawerPanelElement,
    toolbarElement,
    origin
  }
  
  if (type === 'toggle') {
    listenClickToggle(button, options)
  } else {
    listenClickClose(button, options)
  }
}

const initAll = () => {
  lsSet('cdv-drawers', [])
  document.querySelectorAll(TOGGLE_BUTTON).forEach((button) => init(button, 'toggle'))
  document.querySelectorAll(CLOSE_BUTTON).forEach((button) => init(button, 'close'))
}

export default {
  initAll,
}
