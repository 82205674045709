import Flickity from 'flickity'
import 'flickity-as-nav-for'
import modals from './modal'

const SLIDESHOW = '.Slideshow'
const MAIN_SLIDESHOW = '.Slideshow__main'
const NAV_SLIDESHOW = '.Slideshow__nav'
const SLIDE = '.Slideshow__slide'
const PREV = '.Slideshow__prev'
const NEXT = '.Slideshow__next'

const baseConfig = {
  draggable: true,
  watchCSS: true, /* Auto responsive init/disable */
  pageDots: false,
  prevNextButtons: false,
}

const init = (slideshow) => {
  const main = slideshow.querySelector(MAIN_SLIDESHOW)
  const nav = slideshow.querySelector(NAV_SLIDESHOW)

  const mainFkt = new Flickity(main, {
    ...baseConfig,
    cellSelector: SLIDE,
    wrapAround: true,
  })

  // Hiding nav flickity using watchCSS causes some error logs
  // It is a known issue with Flickity
  // It would require to watch browser viewport / resize to init/uninit the nav
  // However, those errors don't cause any problem
  if (nav) {
    const navFkt = new Flickity(nav, {
      ...baseConfig,
      asNavFor: main,
    })
  }

  const previous = (e) => mainFkt.previous()
  const next = (e) => mainFkt.next()

  slideshow.querySelector(PREV).addEventListener('click', previous)
  slideshow.querySelector(NEXT).addEventListener('click', next)

  // Return listeners to be removed
  return {
    previous,
    next,
  }
}

const uninit = (slideshow, listeners) => {
  const main = slideshow.querySelector(MAIN_SLIDESHOW)
  const nav = slideshow.querySelector(NAV_SLIDESHOW)

  slideshow.querySelector(PREV).addEventListener('click', listeners.previous)
  slideshow.querySelector(NEXT).addEventListener('click', listeners.next)

  Flickity.data(main).destroy()
  Flickity.data(nav).destroy()
}

const initAll = () => {
  document.querySelectorAll(SLIDESHOW).forEach((slideshow) => {
    const modalId = slideshow.getAttribute('data-slideshow-in-modal')

    if (modalId) {
      modals.on('show', modalId, () => {
        const listeners = init(slideshow)

        modals.once('close', modalId, () => {
          uninit(slideshow, listeners)
        })
      })
    } else {
      init(slideshow)
    }
  })
}

export default {
  initAll,
}
