/*
  Enable Tabs by adding correct accessibility attributes.

  Includes keyboard navigation.
  - Arrows, home and end keys navigates between tabs
  - Tabbing switches focus to panel and ignores other tabs [1]

  HTML structure doesn't matter as long as [role="tab"] are in [role="tablist"]
  And a [role="tab"] can be either <a> or <button>

  Example:
  <ul role="tablist" aria-label="Some tabs">
    <li><button id="tab-a" aria-controls="panel-a" role="tab" aria-selected="true">Link to A</a></li>
    <li><button id="tab-b" aria-controls="panel-b" role="tab" aria-selected="false" tabindex="-1">Link to B</a></li>
  </ul>
  <section id="panel-a" role="tabpanel" aria-labelledby="tab-a" tabindex="0">Panel A</section>
  <section id="panel-b" role="tabpanel" aria-labelledby="tab-b" tabindex="0" hidden="true">Panel B</section>

  Inspired by:
  - https://www.w3.org/TR/wai-aria-practices-1.1/examples/tabs/tabs-1/tabs.html
  - http://web-accessibility.carnegiemuseums.org/code/tabs/
  - https://getbootstrap.com/docs/5.0/components/navs-tabs/#javascript-behavior
*/

import keys from './keyboard'

const TABS_LIST = '[role="tablist"]'
const TAB = '[role="tab"]'
const ACTIVE_TAB = '[role="tab"][aria-selected="true"]'

// Tabs can also be controlled by select on mobile screens
// Although it might be more accessible to change design or use combobox
const SELECT = '[data-tabs-select-role="tablist"]'

const first = (arr) => arr[0]
const last = (arr) => arr[arr.length - 1]

const getTabPanel = (tab) => document.getElementById(tab.getAttribute('aria-controls'))

const getAllTabs = (tab) => tab.closest(TABS_LIST).querySelectorAll(TAB)

const getFirstTab = (tab) => first(getAllTabs(tab))

const getLastTab = (tab) => last(getAllTabs(tab))

const getPreviousOrLastTab = (tab) => {
  const tabs = getAllTabs(tab)
  const index = Array.from(tabs).findIndex(t => t === tab)
  if (tab === first(tabs)) {
    return last(tabs)
  }
  return tabs[index - 1]
}

const getNextOrFirstTab = (tab) => {
  const tabs = getAllTabs(tab)
  const index = Array.from(tabs).findIndex(t => t === tab)
  if (tab === last(tabs)) {
    return first(tabs)
  }
  return tabs[index + 1]
}

const activateTab = (tab) => {
  tab.setAttribute('aria-selected', 'true')
  tab.removeAttribute('tabindex') // See [1]
  getTabPanel(tab).removeAttribute('hidden')
}

const deactivateTab = (tab) => {
  tab.setAttribute('aria-selected', 'false')
  tab.setAttribute('tabindex', '-1') // See [1]
  getTabPanel(tab).setAttribute('hidden', 'true')
}

const switchTab = (nextTab) => {
  const currentTab = nextTab.closest(TABS_LIST).querySelector(ACTIVE_TAB)
  deactivateTab(currentTab)
  activateTab(nextTab)
  nextTab.focus()
}

const switchTabWithKeyboard = (nextTab) => switchTab(nextTab)

const handleClick = (event) => {
  event.preventDefault()
  switchTab(event.target)
}

// Use keydown event to be able to prevent scroll if necessary
// Remember to use preventDefault to prevent scroll when necessary
const handleKeydown = (event) => {
  const key = event.keyCode
  const currentTab = event.target
  switch (key) {
    case keys.END:
      event.preventDefault()
      switchTabWithKeyboard(getLastTab(currentTab))
      break
    case keys.HOME:
      event.preventDefault()
      switchTabWithKeyboard(getFirstTab(currentTab))
      break
  }
}

const handleKeyup = (event) => {
  const key = event.keyCode
  const currentTab = event.target
  switch (key) {
    case keys.LEFT:
      switchTabWithKeyboard(getPreviousOrLastTab(currentTab))
      break
    case keys.RIGHT:
      switchTabWithKeyboard(getNextOrFirstTab(currentTab))
      break
  }
}

const handleSelectChange = (event) => {
  // Get tab from option
  const tab = document.getElementById(event.target.value)
  switchTab(tab)
}

const init = (list) => {
  list.querySelectorAll(TAB).forEach(tab => {
    tab.addEventListener('click', handleClick)
    tab.addEventListener('keydown', handleKeydown)
    tab.addEventListener('keyup', handleKeyup)
  })
}

const initAll = () => {
  document.querySelectorAll(TABS_LIST).forEach(init)
  document.querySelectorAll(SELECT).forEach((select) => {
    select.addEventListener('change', handleSelectChange)
  })
}

export default {
  initAll,
}
