import { on } from 'delegated-events'
import modals from './modal'
import { debounce } from './utilities'

const SEARCH_MODAL = "Search-modal"
const SEARCH_MODAL_TRIGGER = "button[data-search-trigger]"
const SEARCH_FORM = '#Search__form'
const SEARCH_INPUT = '#Search__input'
const RESULTS = '#Search__results'

const search = async ({ form, input, results }) => {
  const data = new FormData(form)

  if (!input.checkValidity()) {
    // Don't send requests if input is invalid
    return input.reportValidity()
  }

  const url = new URL(form.getAttribute('action'), window.location)
  url.search = new URLSearchParams(data)

  try {
    const res = await fetch(url)

    if (!res.ok) {
      throw new Error(response.statusText)
    }

    const { html } = await res.json()

    results.innerHTML = html
  } catch (err) {
    // TODO: Do something about the error
    console.error(err)
  }
}

const handleSubmit = (elements) => async (e) => {
  e.preventDefault()
  await search(elements)
}

const handleChange = (elements) => async (e) => {
  await search(elements)
}

const init = () => {
  const elements = {
    form: document.querySelector(SEARCH_FORM),
    input: document.querySelector(SEARCH_INPUT),
    results: document.querySelector(RESULTS),
  }

  on('click', SEARCH_MODAL_TRIGGER, (e) => {
    // Open modal
    modals.show(SEARCH_MODAL)
  })

  if (elements.form) {
    elements.form.addEventListener('submit', handleSubmit(elements))
  }

  if (elements.input) {
    elements.input.addEventListener('input', debounce(handleChange(elements), 250))
  }
}

// There is only 1 search component per page but keep initAll for convention
const initAll = init

export default {
  initAll,
}
