const GROUPED_DETAILS = 'details[data-details-group]'

const close = (dropdown) => {
  dropdown.open = false
}

// Allow to group details together to keep only one active at the same time
const listenToggle = (detailsElement) => {
  const group = detailsElement.getAttribute('data-details-group')
  const otherDetails = document.querySelectorAll(`[data-details-group="${group}"]`)
  detailsElement.addEventListener("toggle", () => {
    if (detailsElement.open) {
      otherDetails.forEach(d => {
        if (d !== detailsElement) {
          close(d)
        }
      })
    }
  })
}

const initAll = () => {
  document.querySelectorAll(GROUPED_DETAILS).forEach(listenToggle)
}

export default {
  initAll,
}
