import { on } from 'delegated-events'

const DROPDOWN = '.Dropdown'
const BUTTON = '.Dropdown__button'
const OPTION = '.Dropdown__option'

const isOutside = (el) => el.closest(DROPDOWN) === null

const close = (dropdown) => {
  dropdown.open = false
}

const handleOutsideClick = (e) => {
  if (isOutside(e.target)) {
    document.querySelectorAll(DROPDOWN).forEach(close)
  }
}

const handleClick = (e) => {
  const option = e.target.closest(OPTION)

  if (option) {
    const dropdown = option.closest(DROPDOWN)
    dropdown.querySelector(BUTTON).textContent = option.textContent
    close(dropdown)
  }
}

const initAll = () => {
  on('click', DROPDOWN, handleClick)
  document.addEventListener('click', handleOutsideClick)
}

export default {
  initAll,
}
