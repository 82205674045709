const NAVBAR = 'Navbar'
const TOOLBAR = 'Toolbar'
const FOOTER = 'Footer'

const initAll = () => {
  const navbarElement = (document.getElementsByClassName(NAVBAR))[0]
  const toolbarElement = (document.getElementsByClassName(TOOLBAR))[0]
  const footerElement = (document.getElementsByClassName(FOOTER))[0]
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
  }

  const intersectionCallback = (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting && entry.intersectionRatio >= .3) {
        navbarElement.classList.add('Navbar--retracted-footer')
        toolbarElement.classList.add('Toolbar--over-footer')
      } else {
        navbarElement.classList.remove('Navbar--retracted-footer')
        toolbarElement.classList.remove('Toolbar--over-footer')
      }
    });
  }

  const footerObserver = new IntersectionObserver(intersectionCallback, options);

  if (footerElement) {
    footerObserver.observe(footerElement)
  }
}

export default {
  initAll,
}
