import smoothscroll from 'smoothscroll-polyfill'
import drawer from './drawer'
import ajax from './ajax'
import slidebars from './slidebar'
import toggles from './toggle_class'
import readMore from './read_more'
import anchor from './anchor'
import tabs from './tabs'
import popups from './popup'
import clipboard from './clipboard'
import navbar from './navbar'
import search from './search'
import dropdowns from './dropdown'
import tripadvisor from './tripadvisor'
import journey from './journey'
import filters from './filter'
import slideshows from './slideshow'
import modals from './modal'
import libraries from './library'
import toolbar from './toolbar'
import footer from './footer'
import instagram from './instagram'
import navigationModule from './navigation_module'
import alert from './alert'
import details from './details'
import detailsGroups from './details_group'
import carousels from './carousel'
import header from './header'
import journeyTimeline from './journey_timeline'
import journeyCard from './journey_card'
import lazyVideos from './lazy_video'
import eventDatepicker from './event_datepicker'

smoothscroll.polyfill()

// Modules who use event delegation should be put in toInitOnly to prevent duplicate event listeners due to turbolinks
// Maybe we should consider to rewrite all modules to use event delegation
// This is a workaround to make them both coexist
const toInitOnly = [ajax, dropdowns, journey, filters, libraries, navigationModule, details]
const toRerender = [
  drawer,
  slidebars,
  toggles,
  readMore,
  anchor,
  tabs,
  popups,
  clipboard,
  navbar,
  search,
  tripadvisor,
  slideshows,
  modals,
  toolbar,
  footer,
  instagram,
  alert,
  carousels,
  header,
  journeyTimeline,
  detailsGroups,
  journeyCard,
  lazyVideos,
  eventDatepicker
]
const toInit = [...toInitOnly, ...toRerender]

whenReady(toInit, toRerender)

function whenReady(allModules, modulesToRerender) {
  const init = () => {
    // Prevent browser scroll position restoration on page change
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual'
    }

    allModules.forEach(module => module.initAll())
  }

  // Some modules need to clear some things like intervals between turbolinks renders
  const rerender = () => {
    modulesToRerender.forEach(module => module.initAll())
  }

  const uninit = () => {
    allModules.forEach(module => module.uninitAll?.())
  }

  // https://stackoverflow.com/a/39993724/5873144
  if (document.readyState !== 'loading') {
    init()
  } else {
    document.addEventListener('DOMContentLoaded', init)
  }
  document.addEventListener('turbolinks:before-render', uninit)
  document.addEventListener('turbolinks:render', rerender)
}
