import { hashCode } from './utilities'

const CLOSE_BUTTON = 'button[data-alert-close]'
const LS_ITEM = 'cdv-alerts'

const close = (alertElement, alertTextHash) => {
  const alertList = JSON.parse(window.localStorage.getItem(LS_ITEM)) || []
  alertList.push(alertTextHash)
  window.localStorage.setItem(LS_ITEM, JSON.stringify(alertList))
  alertElement.remove()
}

const listenClick = (button, alertElement, alertTextHash) => button.addEventListener('click', () => {
  close(alertElement, alertTextHash)
})

const checkAlertStatus = (button) => {
  const alertList = JSON.parse(window.localStorage.getItem(LS_ITEM)) || []
  const controlTarget = button.getAttribute('aria-controls')
  const alertElement = document.getElementById(controlTarget)
  const alertTextElement = document.getElementById(`${controlTarget}-text`)
  const alertTextHash = hashCode(alertTextElement.innerText)

  if (alertList.includes(alertTextHash)) {
    alertElement.remove()
  } else {
    setTimeout(() => {
      alertElement.classList.remove('hidden:i')
      alertElement.classList.add(`${controlTarget}--show`)
      listenClick(button, alertElement, alertTextHash)
    }, 500)
  }
}

const initAll = () => {
  document.querySelectorAll(CLOSE_BUTTON).forEach(checkAlertStatus)
}

export default {
  initAll,
}
