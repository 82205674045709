import Swiper, { Scrollbar, Mousewheel, Navigation } from 'swiper/core'

Swiper.use([Scrollbar, Mousewheel, Navigation])

const CAROUSEL = '[data-carousel]'

const init = (carousel) => {
  const { carouselSlidebar } = carousel.dataset
  const slidebar = document.querySelector(carouselSlidebar)

  new Swiper(carousel, {
    slidesPerView: 'auto',
    slideClass: 'Carousel__item',
    mousewheel: {
      forceToAxis: true
    },
    scrollbar: {
      el: slidebar,
      dragClass: 'Carousel__slidebar-drag',
      hide: false,
    },
    navigation: {
      nextEl: '.Carousel__next',
      prevEl: '.Carousel__prev',
      disabledClass: 'disabled',
      hiddenClass: 'hidden'
    },
    breakpoints: {
      1200: {
        slidesPerGroup: 3
      }
    }
  })
}

const initAll = () => document.querySelectorAll(CAROUSEL).forEach(init)

export default {
  initAll,
}
