/*
  Track scroll position of a target element
  It sets css custom properties with scroll values on the slidebar

  Options:
  data-sidebar boolean: Enables tracking
  data-target id: Element whose scroll should be tracked

  Inspired by https://css-tricks.com/books/greatest-css-tricks/scroll-animation/
*/

import ResizeObserver from 'resize-observer-polyfill'

const SLIDEBAR = '[data-slidebar]'

const getCenter = (el) => el.offsetLeft + el.offsetWidth / 2;

const updateSlidebar = (slidebar, target, timelineItems) => {
  const { scrollLeft, scrollWidth, offsetWidth } = target
  slidebar.style.setProperty('--Slidebar-visible-ratio', offsetWidth / scrollWidth)
  slidebar.style.setProperty('--Slidebar-scroll', scrollLeft / scrollWidth)
  timelineItems.forEach(item => {
    const center = getCenter(item) - item.parentElement.offsetLeft
    if (center / item.parentElement.offsetWidth < offsetWidth / scrollWidth + scrollLeft / scrollWidth) {
      item.classList.add('active')
    } else {
      item.classList.remove('active')
    }
  })
}

const init = (slidebar) => {
  const target = document.getElementById(slidebar.dataset.target)
  const timelineItems = document.querySelectorAll(slidebar.dataset.timelineItems)

  // Set initial values
  updateSlidebar(slidebar, target, timelineItems)

  // Handle resize
  const ro = new ResizeObserver(entries => {
    entries.forEach(entry => updateSlidebar(slidebar, entry.target, timelineItems))
  })
  ro.observe(target)

  // Handle scroll
  target.addEventListener('scroll', (event) => updateSlidebar(slidebar, event.target, timelineItems), false)
}

const initAll = () => document.querySelectorAll(SLIDEBAR).forEach(init)

export default {
  initAll,
}
