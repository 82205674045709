const LAZY_VIDEO = '[data-lazy-video]'

const initAll = () => {
  const lazyVideos = document.querySelectorAll(LAZY_VIDEO)

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        return
      }
      const video = entry.target

      for (let source of video.children) {
        source.src = source.dataset.src
      }

      video.load()

      if (video.hasAttribute('autoplay')) {
        video.play()
      }

      observer.unobserve(video)
    })
  })

  lazyVideos.forEach(v => observer.observe(v))
}

export default {
  initAll,
}
