const POPUP = 'a[data-popup]'

const init = (link) => {
  link.addEventListener('click', event => {
    event.preventDefault()
    window.open(link.getAttribute('href'), '_blank', "width=650,height=450")
  })
}

const initAll = () => document.querySelectorAll(POPUP).forEach(init)

export default {
  initAll,
}
