export function hashCode(str) {
  let hash = 0;
  if (str.length == 0) return hash;
  for (let i = 0; i < str.length; i++) {
    let char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

export function lsGet(property) {
  return JSON.parse(window.localStorage.getItem(property)) || []
}

export function lsSet(property, value) {
  window.localStorage.setItem(property, JSON.stringify(value))
}

export function debounce(func, wait, immediate) {
  let timeout
  return function () {
    let context = this
    let args = arguments
    let later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    let callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export function onScrollDirection({ up, down }) {
  let prevScrollY = window.scrollY

  const handleScroll = (e) => {
    const scrollY = e.currentTarget.scrollY
    if (prevScrollY > scrollY) { // Scroll up
      up()
    } else { // Scroll down
      down()
    }
    prevScrollY = scrollY
  }

  window.addEventListener('scroll', handleScroll)

  // Return cleanup function
  return () => window.removeEventListener('scroll', handleScroll)
}
