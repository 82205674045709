import Swiper, { Scrollbar, Mousewheel, Navigation } from 'swiper/core'

Swiper.use([Scrollbar, Mousewheel, Navigation])

const CAROUSEL = '[data-journey-timeline]'
const CONTROL = '[data-journey-timeline-control]'

const universeId = (el) => el.getAttribute('data-journey-timeline-universe')

const computeProgress = (allUniverses, activeSlideUniverseId, activeSlide, offset, isEnd) => {
  if (isEnd) {
    return 1
  }

  // 0.0 -> 1.0
  // Add .015 to go a little beyond step indicator,
  // It looks nicer this way than when it stops right at the step
  let progress = offset + .015

  // all universes progress
  const universeIndex = allUniverses.findIndex(u => u.id === activeSlideUniverseId)
  const universe = allUniverses[universeIndex]
  progress += universeIndex / allUniverses.length

  // progress in the current active universe
  const slideIndex = universe.slides.findIndex(s => s === activeSlide)
  progress += slideIndex / universe.slides.length / allUniverses.length

  return progress
}

const computeControlOffset = (firstControl) => {
  const item = firstControl.parentElement
  const list = item.parentElement
  return getRelativeCenter(item, list) / list.offsetWidth
}

const getCenter = (el) => el.offsetLeft + el.offsetWidth / 2;
const getRelativeCenter = (el, relativeTo) => getCenter(el) - relativeTo.offsetLeft

const init = (carousel) => {
  const { journeyTimelineSlidebar, journeyTimelineProgress } = carousel.dataset
  const slidebar = document.querySelector(journeyTimelineSlidebar)
  const progressBar = document.querySelector(journeyTimelineProgress)

  const swiper = new Swiper(carousel, {
    slidesPerView: 'auto',
    slideClass: 'Carousel__item',
    centeredSlides: false,
    centeredSlidesBounds: false,
    mousewheel: {
      forceToAxis: true
    },
    scrollbar: {
      el: slidebar,
      dragClass: 'Carousel__slidebar-drag'
    },
    navigation: {
      //nextEl: '.Carousel__next',
      //prevEl: '.Carousel__prev',
      disabledClass: 'disabled',
      hiddenClass: 'hidden'
    },
    breakpoints: {
      1200: {
        slidesPerGroup: 1
      }
    }
  })

  const allControls = [...document.querySelectorAll(CONTROL)]
  const activeControlClass = allControls[0]?.getAttribute('data-journey-timeline-control-active-class')

  // [{ id, slides }]
  const allUniverses = swiper.slides.reduce((acc, slide) => {
    const prev = acc[acc.length - 1]
    const slideUniverse = slide.getAttribute('data-journey-timeline-universe')
    if (prev?.id === slideUniverse) {
      prev.slides.push(slide)
    } else {
      acc.push({
        id: slideUniverse,
        slides: [slide]
      })
    }
    return acc
  }, [])

  // Offset is caused by centered items
  let offset = 0
  let activeControl = allControls[0]

  const updateOffset = () => {
    // default to 0 to prevent ugly progress flash
    // as offset is NaN when timeline is not displayed
    offset = computeControlOffset(allControls[0]) || 0
  }

  const updateProgress = () => {
    const activeSlide = swiper.slides[swiper.activeIndex]
    const progress = computeProgress(allUniverses, universeId(activeSlide), activeSlide, offset, swiper.isEnd)
    progressBar.style.setProperty('--Carousel-progress', progress)
  }

  // Set initial values
  updateOffset()
  updateProgress()

  window.addEventListener('resize', (e) => {
    const prevOffset = offset
    updateOffset()
    if (!prevOffset && offset) {
      updateProgress()
    }
  })

  const updateArrows = () => {
    if(swiper.isEnd) { 
      document.querySelector('.Carousel__next').classList.add('disabled')
    } else {
      document.querySelector('.Carousel__next').classList.remove('disabled')
    }
    if(swiper.isBeginning) { 
      document.querySelector('.Carousel__prev').classList.add('disabled')
    } else {
      document.querySelector('.Carousel__prev').classList.remove('disabled')
    }
  }

  document.querySelector('.Carousel__next').addEventListener('click', (e) => {
    e.preventDefault();
    swiper.slideTo(swiper.activeIndex + 3)
    updateArrows()
  })

  document.querySelector('.Carousel__prev').classList.add('disabled')
  document.querySelector('.Carousel__prev').addEventListener('click', (e) => {
    e.preventDefault();
    swiper.slideTo(swiper.activeIndex - 3)
    updateArrows()
  })
  swiper.on('slideChange', () => {
    updateArrows()
    const newActiveSlide = swiper.slides[swiper.activeIndex]
    const newSlideUniverseId = universeId(newActiveSlide)
    const newActiveControl = allControls.find(c => universeId(c) === newSlideUniverseId)
    updateProgress()
    activeControl.parentElement.classList.remove(activeControlClass)
    newActiveControl.parentElement.classList.add(activeControlClass)
    activeControl = newActiveControl
  })

  allControls.forEach((control, index) => {
    const targetSlide = allUniverses[index].slides[0]
    const targetSlideIndex = swiper.slides.findIndex(slide => slide === targetSlide)
    control.addEventListener('click', (e) => swiper.slideTo(targetSlideIndex))
  })
}

const initAll = () => document.querySelectorAll(CAROUSEL).forEach(init)

export default {
  initAll,
}
