import flatpickr from 'flatpickr'
import 'flatpickr/dist/l10n/fr.js'

import ajax from './ajax'

const BUTTON = '[data-event-datepicker="button"]'

const language = document.documentElement.lang

flatpickr.l10ns.default.weekdays.shorthand = ["S", "M", "T", "W", "T", "F", "S"]
flatpickr.l10ns.fr.weekdays.shorthand = ["D", "L", "M", "M", "J", "V", "S"]
flatpickr.l10ns.fr.months.longhand = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
]

const init = (button) => {
  const options = {
    wrap: true,
    static: true,
    monthSelectorType: 'static',
    prevArrow: '<i class="Icon Icon--chevron-down Icon--rotate-90" aria-hidden="true"></i>',
    nextArrow: '<i class="Icon Icon--chevron-down Icon--rotate-90 Icon--flip" aria-hidden="true"></i>',
    onChange: function(selectedDates, dateStr, instance) {
      button.dataset.url = button.dataset.url.replace('date=', 'date=' + dateStr)
      ajax.handleClick(null, button)
    },
  }

  if (language in flatpickr.l10ns) {
    options['locale'] = language
  }

  flatpickr(button, options)
}

const initAll = () => {
  document.querySelectorAll(BUTTON).forEach(init)
}

export default {
  initAll,
}
