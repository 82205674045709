const CLIPBOARD = '[data-clipboard]'

const init = (button) => {
  button.addEventListener('click', event => {
    event.preventDefault()
    const valueToCopy = button.getAttribute('data-clipboard-value')

    // Create input to hold value
    const input = document.createElement('input')
    document.body.appendChild(input)
    input.value = valueToCopy
    input.select()

    // Copy selected value
    document.execCommand('copy')

    // Clean
    input.remove()
  })
}

const initAll = () => document.querySelectorAll(CLIPBOARD).forEach(init)

export default {
  initAll,
}
