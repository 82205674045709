/*
  Ajax navigation
  Loads a new page and replaces parts of the current page with parts of a new page

  /!\ IMPORTANT
  Add [data-turbolinks="false"] on the link otherwise there will be issues

  Options:
  data-ajax boolean: Enables ajax navigation
  data-ajax-target id: Part of the page to update
  data-ajax-mode append|replace: Either update by appending new elements or replacing previous ones
*/

import { on } from 'delegated-events'
import eventDatepicker from './event_datepicker'

const AJAX_LINK = 'a[data-ajax]'

const fetchHTML = async (url) => {
  const res = await fetch(url)

  if (!res.ok) {
    throw new Error(response.statusText)
  }

  const rawHTML = await res.text()
  return new DOMParser().parseFromString(rawHTML, 'text/html')
}

const replaceChildren = (element, newElement) => {
  while (element.firstChild) {
    element.removeChild(element.lastChild);
  }
  element.append(...newElement.children)
}

const handleClick = async (event, link = event.currentTarget) => {
  if (event) event.preventDefault()
  const url = link.getAttribute('href') || link.dataset.url
  const {
    ajaxMode,
    ajaxTarget,
    ajaxLink,
    ajaxUpdateTitle,
    ajaxRetrieve = ajaxTarget,
    ajaxHistory = "true",
  } = link.dataset

  const isAjaxHistoryEnabled = ajaxHistory !== "false"

  if (ajaxMode !== 'append' && isAjaxHistoryEnabled) {
    window.history.pushState({}, '', url)
  }

  try {
    const newDocument = await fetchHTML(url)
    const target = document.getElementById(ajaxTarget)
    const newTarget = newDocument.getElementById(ajaxRetrieve)

    if (ajaxUpdateTitle) {
      const newHeading = newDocument.querySelector('[data-ajax-page-title]').textContent
      document.querySelector('[data-ajax-page-title]').textContent = newHeading

      const newTitle = newDocument.head.querySelector('title').textContent
      document.head.querySelector('title').textContent = newTitle
    }

    // Insert new HTML
    switch (ajaxMode) {
      case 'append':
        target.append(...newTarget.children)
        break
      case 'replace':
      default:
        replaceChildren(target, newTarget)
    }

    // Update link
    if (ajaxLink) {
      const linkToUpdate = document.getElementById(ajaxLink)
      const newLink = newDocument.getElementById(ajaxLink)

      if (!newLink) {
        linkToUpdate.classList.add('hidden:i')
      } else if (linkToUpdate && newLink) {
        linkToUpdate.setAttribute('href', newLink.getAttribute('href'))
        linkToUpdate.classList.remove('hidden:i')
      }
    }

    // Dirty Safari fix
    // https://stackoverflow.com/questions/45487105/ajax-loaded-images-in-safari-not-respecting-srcset
    target.querySelectorAll('img').forEach(img => { img.outerHTML = img.outerHTML })

    // Reinit event datepicker
    eventDatepicker.initAll()
  } catch (err) {
    console.error(err)
  }
}

const initAll = () => on('click', AJAX_LINK, handleClick)

export default {
  initAll,
  fetchHTML,
  handleClick
}
