import { on } from 'delegated-events'

const MAP_BUTTON = 'button[data-thematic-map-control]'

const toggleMap = (e) => {
  const button = e.currentTarget
  const map = document.getElementById(button.getAttribute('aria-controls'))
  const target = document.querySelector('.Journey-page__maps')

  document.querySelectorAll('.Thematic-card').forEach(card => card.classList.remove('Thematic-card--active'))
  document.querySelectorAll('.Journey-page__map-section').forEach(map => map.classList.add('hidden'))

  button.closest('.Thematic-card').classList.add('Thematic-card--active')
  map.classList.remove('hidden')

  target.scrollIntoView({
		block: 'start',
		behavior: 'smooth',
		inline: 'nearest'
	})
}


const initAll = () => {
  on('click', MAP_BUTTON, toggleMap)
}

export default {
  initAll,
}
