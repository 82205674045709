import ajax from './ajax'
import { lsGet, onScrollDirection } from './utilities'

const TOOLBAR = '.Toolbar'
const HEADER = '.Header'
const CALENDAR_DRAWER = 'Toolbar__drawer--calendar'
const TOOLBAR_CALENDAR_WRAPPER = '#Toolbar__calendar-wrapper'
const CALENDAR_EMBED = '#Events-embed'

const setScrollUp = (toolbar) => {
  toolbar.classList.remove('Toolbar--scroll-down')
  toolbar.classList.add('Toolbar--scroll-up')
}

const setScrollDown = (toolbar) => {
  toolbar.classList.remove('Toolbar--scroll-up')
  toolbar.classList.add('Toolbar--scroll-down')
}

const setToolbarVisibilityOverHeader = (toolbar) => {
  toolbar.classList.add('Toolbar--over-header')
}

const setToolbarVisibilityOutsideHeader = (toolbar) => {
  toolbar.classList.remove('Toolbar--over-header')
}

const headerObserverOptions = {
  root: null,
  rootMargin: '0px',
  threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
}

const initAll = () => {
  // Hide or show Toolbar after header on mobile
  const toolbarElement = document.querySelector(TOOLBAR)
  const headerElement = document.querySelector(HEADER)

  if (toolbarElement) {
    if (headerElement) {
      const intersectionCallback = (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting && entry.intersectionRatio >= 0.6) {
            setToolbarVisibilityOverHeader(toolbarElement)
          } else {
            setToolbarVisibilityOutsideHeader(toolbarElement)
          }
        })
      }

      const headerObserver = new IntersectionObserver(intersectionCallback, headerObserverOptions)
      headerObserver.observe(headerElement)
    }

    onScrollDirection({
      up: () => setScrollUp(toolbarElement),
      down: () => setScrollDown(toolbarElement),
    })
  }

  // CALENDAR
  const calendarDrawer = document.getElementById(CALENDAR_DRAWER)
  const drawerContent = document.querySelector(TOOLBAR_CALENDAR_WRAPPER)

  if (drawerContent) {
    const { toolbarCalendarUrl } = drawerContent?.dataset

    const calendarObserver = new MutationObserver((mutations) => {
      mutations.forEach(async (mutation) => {
        if (mutation.type == 'attributes') {
          const firstTime = drawerContent.children.length === 0
          const drawerList = lsGet('cdv-drawers')

          if (drawerList.includes(CALENDAR_DRAWER) && firstTime) {
            const calendarDOM = await ajax.fetchHTML(toolbarCalendarUrl)
            const calendar = calendarDOM.querySelector(CALENDAR_EMBED)

            drawerContent.append(calendar)

            // Dirty Safari fix
            // https://stackoverflow.com/questions/45487105/ajax-loaded-images-in-safari-not-respecting-srcset
            drawerContent.querySelectorAll('img').forEach(img => { img.outerHTML = img.outerHTML })
            setTimeout(() => {
              drawerContent.classList.remove('Toolbar__calendar-wrapper--first-try')
            }, 600)
          }
        }
      })
    })

    calendarObserver.observe(calendarDrawer, {
      attributes: true //configure it to listen to attribute changes
    })
  }
}

export default {
  initAll,
}
