const LOAD_ANCHOR = "[data-load-anchor]";

const init = (anchor) => {
  if (anchor.dataset.loadAnchor === '') {
    anchor.scrollIntoView()
  } else {
    window.scrollTo(0, anchor.getBoundingClientRect().top + parseInt(anchor.dataset.loadAnchor))
  }
}

const initAll = () => document.querySelectorAll(LOAD_ANCHOR).forEach(init);

export default {
  initAll,
};
