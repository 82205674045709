const TOGGLE_BUTTON = "button[data-tripadvisor-target]";

const init = (button) =>
  button.addEventListener("click", () => {
    const {
      tripadvisorCurrentTarget,
      tripadvisorTarget,
      tripadvisorClass,
    } = button.dataset;
    document
      .querySelector(tripadvisorCurrentTarget)
      .classList.toggle(tripadvisorClass);
    document
      .querySelector(tripadvisorTarget)
      .classList.toggle(tripadvisorClass);
  });

const initAll = () => document.querySelectorAll(TOGGLE_BUTTON).forEach(init);

export default {
  initAll,
};
