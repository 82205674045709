import { on } from 'delegated-events'

const FILTER = 'button[data-filter]'
const TARGETS = '[data-filter-group]'

const matchesFilter = (filter) => (el) => el.getAttribute('data-filter-group').split(',').includes(filter)

const filterTargetElements = (e) => {
  const button = e.currentTarget
  const { filter, filterSort, filterClass } = button.dataset
  const controlledWrapper = document.getElementById(button.getAttribute('aria-controls'))
  const targets = [...controlledWrapper.querySelectorAll(TARGETS)]
  const targetsWrapper = targets[0].parentElement
  const toShow = targets.filter(matchesFilter(filter))
  targets.forEach(el => el.classList.add(filterClass))
  toShow.forEach(el => el.classList.remove(filterClass))

  // .filter(Boolean) removes empty ids
  const sorted = filterSort.split(',').filter(Boolean).map(id => toShow.find(el => el.getAttribute('data-filter-id') === id))

  sorted.forEach(el => {
    targetsWrapper.appendChild(el)
  })

  // Dirty workaround. It would be cleaner not to break separation of concerns
  if (controlledWrapper.swiper) {
    controlledWrapper.swiper.update()
  }
}

const initAll = () => {
  on('click', FILTER, filterTargetElements)
}

export default {
  initAll,
}
