/*
  Example :
  - For toggling classes without an impact on accessibility
    <button type="button" data-toggle-class="blue" data-target=".my-target"></button>
  - For toggling classes with an impact on accessibility
    <button type="button" data-toggle-class="hidden" aria-controls="my-target-id" aria-expanded="false"></button>
*/

// Enforce usage of a button element for accessibility and semantics
const TOGGLE_BUTTON = 'button[data-toggle-class]'

const idListToSelector = (ids) => '#' + ids.replace(/\s+/g, ',#')

const init = (button) =>
  button.addEventListener('click', () => {
    // data-target, data-toggle-class
    const { target, toggleClass } = button.dataset

    // If both aria-controls and data-target are present, use data-target for toggling class and aria-controls for a11y
    const ariaControls = button.getAttribute('aria-controls')
    const accessibleTarget = ariaControls
      ? idListToSelector(ariaControls)
      : null
    const targetSelector = target || accessibleTarget

    document.querySelectorAll(targetSelector).forEach((el) => {
      el.classList.toggle(toggleClass)
      if (button.hasAttribute('aria-expanded')) {
        button.setAttribute(
          'aria-expanded',
          button.getAttribute('aria-expanded') === 'false'
        )
      }
    })
  })

const initAll = () => document.querySelectorAll(TOGGLE_BUTTON).forEach(init)

export default {
  initAll,
}
