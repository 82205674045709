const NAVBAR = '.Navbar'
const NAVBAR_THRESHOLD = '.Navbar__scroll-threshold'
const NAVBAR_SCROLLED_CLASSNAME = 'Navbar--scrolled'

const handleScroll = (navbar) => (entries, observer) => entries.forEach((entry) => {
  if (entry.boundingClientRect.y < 0) {
    navbar.classList.add(NAVBAR_SCROLLED_CLASSNAME)
  } else {
    navbar.classList.remove(NAVBAR_SCROLLED_CLASSNAME)
  }
})

const init = () => {
  const navbar = document.querySelector(NAVBAR)
  const threshold = document.querySelector(NAVBAR_THRESHOLD)

  // Prevent errors if some pages don't have a navbar
  if (!navbar || !threshold) {
    return
  }

  const observer = new IntersectionObserver(handleScroll(navbar)) // Better performance and reliability than 'scroll' listener
  observer.observe(threshold)

  let lastScrollTop = 0

  document.addEventListener('scroll', () => {
    const st = window.pageYOffset || document.documentElement.scrollTop
    if (st > lastScrollTop){ // downscroll
      navbar.classList.add('Navbar--retracting-scroll')
      setTimeout(() => {
        navbar.classList.remove('Navbar--retracting-scroll')
        navbar.classList.add('Navbar--retracted-scroll')
      }, 250)
    } else { // upscroll
      navbar.classList.remove('Navbar--retracted-scroll')
    }
    lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
  }, false);
}

// There is only 1 navbar but keep initAll for convention
const initAll = init

export default {
  initAll,
}
