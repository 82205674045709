import Flickity from 'flickity'

const CAROUSEL = '[data-instagram-carousel]'

const baseConfig = {
  draggable: true,
  watchCSS: true, /* Auto responsive init/disable */
  pageDots: false,
  prevNextButtons: false,
}

const init = (carousel) => {
  new Flickity(carousel, {
    ...baseConfig,
    wrapAround: true,
  })
}

const initAll = () => {
  document.querySelectorAll(CAROUSEL).forEach(init)
}

export default {
  initAll,
}
