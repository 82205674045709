const DETAILS = '[data-details]'

const close = (details) => {
  details.open = false
}

const handleOutsideClick = (e) => {
  const closest = e.target.closest(DETAILS)
  const isOutside = closest === null
  let detailsToClose = document.querySelectorAll(DETAILS)

  if (!isOutside) {
    detailsToClose = [...detailsToClose].filter(d => d !== closest)
  }

  detailsToClose.forEach(close)
}

const initAll = () => {
  document.addEventListener('click', handleOutsideClick)
}

export default {
  initAll,
}
