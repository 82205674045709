import { on } from 'delegated-events'
import modals from './modal'

const LEGAL_MODAL = "[data-library-legal-modal]"
const LEGAL_MODAL_TRIGGER = "button[data-library-legal-trigger]"
const LEGAL_FORM = 'form[data-library-legal-form]'

const acceptTerms = () => {
  localStorage.setItem('consentsToLibraryTerms', true)
}

const hasAcceptedTerms = () => Boolean(localStorage.getItem('consentsToLibraryTerms'))

const initAll = () => {
  on('click', LEGAL_MODAL_TRIGGER, (e) => {
    const button = e.currentTarget
    const { libraryLegalTrigger, libraryAlbumModal } = button.dataset

    if (hasAcceptedTerms()) {
      // Skip legal modal
      return modals.show(libraryAlbumModal)
    }

    // Store album modal to open on legal modal
    document.getElementById(libraryLegalTrigger).dataset.libraryAlbumModal = libraryAlbumModal

    // Open legal modal
    modals.show(libraryLegalTrigger)
  })

  on('submit', LEGAL_FORM, (e) => {
    e.preventDefault()
    const form = e.currentTarget
    // Form is validated with HTML5, we don't need to check validity in JS

    // Cache user consent
    acceptTerms()

    const parentModal = form.closest(LEGAL_MODAL)

    // Close legal modal
    modals.close(parentModal.id)

    // Open album modal whose id was previously stored on legal modal
    modals.show(parentModal.dataset.libraryAlbumModal)
  })
}

export default {
  initAll,
}
