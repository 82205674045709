const JOURNEY_CARD = '[data-journey-card]'
const JOURNEY_CARD_VIDEO = '[data-journey-card-video]'

const init = (card) => {
  const video = card.querySelector('video')

  if (video) {
    card.addEventListener('mouseenter', e => video.play())
    card.addEventListener('mouseleave', e => video.pause())
  }
}

const initAll = () => {
  if (!window.matchMedia('(hover: hover)').matches) {
    return
  }

  document.querySelectorAll(JOURNEY_CARD).forEach(init)

  const lazyVideos = document.querySelectorAll(JOURNEY_CARD_VIDEO)

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        return
      }
      const video = entry.target

      for (let source of video.children) {
        source.src = source.dataset.src
      }

      video.load()
      observer.unobserve(video)
    })
  })

  lazyVideos.forEach(v => observer.observe(v))
}

export default {
  initAll,
}
