import MicroModal from 'micromodal'

// I implemented a custom event system for modals.
// However upon enlightenment, I realised maybe it would be better
// to reuse delegated-events library or native eventListeners
const listeners = {
  show: {},
  close: {},
}

const handleEvent = (event) => (modal) => {
  const modalListeners = listeners[event][modal.id]
  if (modalListeners) {
    const callbacks = Object.values(modalListeners)
    callbacks.forEach(cb => cb(modal))
  }
}

const config = {
  onShow: handleEvent('show'),
  onClose: (modal, el, event) => {
    // Fix https://github.com/ghosh/Micromodal/issues/324
    // ?. because .show(), .close() do not provide event object
    event?.preventDefault()
    event?.stopPropagation()
    handleEvent('close')(modal, el, event)
  },
  openTrigger: 'data-modal-trigger',
  closeTrigger: 'data-modal-close',
  openClass: 'Modal--open',
  disableScroll: true,
  awaitCloseAnimation: true,
}

const initAll = () => {
  MicroModal.init(config)
}

const show = (id) => MicroModal.show(id, config)
const close = (id) => MicroModal.close(id)

const on = (event, modalId, callback) => {
  if (!listeners[event][modalId]) {
    listeners[event][modalId] = {}
  }
  listeners[event][modalId][callback] = callback
}

const once = (event, modalId, callback) => {
  on(event, modalId, (...args) => {
    callback(...args)
    delete listeners[event][modalId][callback]
  })
}

export default {
  initAll,
  show,
  close,
  on,
  once,
}
