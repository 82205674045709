import Swiper, { Pagination, Mousewheel, Autoplay } from 'swiper/core'

Swiper.use([Pagination, Mousewheel, Autoplay])

const HEADER_CAROUSEL = '[data-header-carousel]'
const HEADER_PROGRESS = '[data-header-carousel-progress]'
const HEADER_CONTROL = '[data-header-carousel-control]'

const init = (carousel) => {
  const progress = document.querySelector(HEADER_PROGRESS)

  const swiper = new Swiper(carousel, {
    speed: 650,
    slidesPerView: 1,
    slideClass: 'Header__item',
    wrapperClass: 'Header__carousel-wrapper',
    pagination: {
      el: progress,
      type: 'progressbar',
      progressbarFillClass: 'Header__progress-fill',
    },
    mousewheel: {
      forceToAxis: true
    },
    autoplay: {
      delay: 6000,
    },
  })

  const allControls = document.querySelectorAll(HEADER_CONTROL)

  swiper.on('slideChange', () => {
    const newActiveControl = allControls[swiper.activeIndex]
    allControls.forEach(c => c.classList.remove(c.getAttribute('data-header-carousel-control-active-class')))
    newActiveControl.classList.add(newActiveControl.getAttribute('data-header-carousel-control-active-class'))
  })

  allControls.forEach(control => {
    const targetSlideIndex = control.getAttribute('data-header-carousel-control')
    control.addEventListener('click', (e) => swiper.slideTo(targetSlideIndex))
  })
}

const initAll = () => {
  const carousel = document.querySelector(HEADER_CAROUSEL)
  if (carousel) {
    init(carousel)
  }
}

export default {
  initAll,
}
