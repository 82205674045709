import { on } from 'delegated-events'

const ANCHOR = '[data-navigation-link-anchor]'

const parseTarget = (href = '') => href.startsWith('#') ? href : href.match(/#.+$/)?.[0]

const initAll = () => {
  on('click', ANCHOR, (e) => {
    e.preventDefault()
    const target = parseTarget(e.target.getAttribute('href'))
    document.querySelector(target)?.scrollIntoView({ behavior: 'smooth' })
  })
}

export default {
  initAll,
}
