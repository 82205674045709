const TOGGLE_BUTTON = "button[data-read-more]";

const init = (button) =>
  button.addEventListener("click", () => {
    // data-read-more-target, data-read-more
    const {
      readMoreTarget,
      readMore,
      readMoreLink,
      readMoreSecondTarget,
    } = button.dataset;
    document.querySelector(readMoreTarget).classList.toggle(readMore);
    button.classList.add("hidden");
    document.querySelector(readMoreSecondTarget).classList.toggle(readMore);
    document.querySelector(readMoreLink).classList.remove("hidden");
  });

const initAll = () => document.querySelectorAll(TOGGLE_BUTTON).forEach(init);

export default {
  initAll,
};
